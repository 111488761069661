import _loadScript3 from "load-script";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _loadScript = _loadScript3;

var _loadScript2 = _interopRequireDefault(_loadScript);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = function (emitter) {
  /**
   * A promise that is resolved when window.onYouTubeIframeAPIReady is called.
   * The promise is resolved with a reference to window.YT object.
   */
  var iframeAPIReady = new Promise(function (resolve) {
    if (window.YT && window.YT.Player && window.YT.Player instanceof Function) {
      resolve(window.YT);
      return;
    } else {
      var protocol = window.location.protocol === "http:" ? "http:" : "https:";
      (0, _loadScript2.default)(protocol + "//www.youtube.com/iframe_api", function (error) {
        if (error) {
          emitter.trigger("error", error);
        }
      });
    }

    var previous = window.onYouTubeIframeAPIReady; // The API will call this function when page has finished downloading
    // the JavaScript for the player API.

    window.onYouTubeIframeAPIReady = function () {
      if (previous) {
        previous();
      }

      resolve(window.YT);
    };
  });
  return iframeAPIReady;
};

exports = exports["default"];
export default exports;